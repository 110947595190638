import Image from 'next/image';
import { Typography } from '@/atoms/00-ui/Typography';
import { cn } from '@/helpers/tailwind';
import { themeColors } from '@/constants/case-studies';
import Link from 'next/link';
import { routeNames } from '@/constants/routes';
import { CaseStudyCardTemplate } from '@/molecules/00-ui/Card';
import { ICaseStudyCardProps } from '@/organisms/00-ui/CaseStudyCard/index.types';

export const CaseStudyCard = ({
  preTitle,
  title,
  description,
  tags,
  technologies,
  image,
  themeColor,
  video,
}: ICaseStudyCardProps) => {
  return (
    <CaseStudyCardTemplate
      imageSlot={
        <>
          {image && (
            <Image
              src={image.src}
              width={image.width}
              height={image.height}
              alt={image.alt}
              loading="lazy"
              placeholder="empty"
              className="object-cover w-full h-full"
            />
          )}
          {video}
        </>
      }
      preTitleSlot={
        <Typography
          component="p"
          className={cn('leading-[24px] text-base uppercase font-medium mb-[12px] relative z-10 line-clamp-1', {
            ['text-grey-9']: themeColor === themeColors.GREEN,
            ['text-purple-7']: themeColor === themeColors.PURPLE,
            ['text-green-7']: themeColor === themeColors.PINK || themeColor === themeColors.DARK_PINK,
          })}
        >
          {preTitle}
        </Typography>
      }
      titleSlot={
        <Typography
          component="h2"
          className="
            text-grey-1 mobile:leading-[30px] text-lg mobile:text-2xl
             mb-[20px] relative z-10 line-clamp-2
          "
        >
          {title}
        </Typography>
      }
      descriptionSlot={
        <Typography
          component="p"
          variant="body2"
          className="
            leading-[25.6px] text-sm opacity-0 line-clamp-[6]
            group-hover:opacity-[100] duration-500 text-white relative z-10
          "
        >
          {description}
        </Typography>
      }
      innerTagsSlot={
        <div
          className="
            flex gap-y-[12px] gap-x-[8px] flex-wrap opacity-[0] group-hover:opacity-[100] duration-500
          "
        >
          {technologies?.list?.map((item) => {
            return (
              <span
                key={item}
                className="
                  transition-colors duration-300 ease-out text-[11px]
                  flex items-center justify-center bg-purple-2
                  rounded-full border-[1px] px-[10px] py-[1px]
                  text-center
                  text-purple-8 border-purple-2
                  leading-[20px]
                "
              >
                {item}
              </span>
            );
          })}
          {technologies?.more && (
            <span className="text-purple-7 italic text-[11px] font-semibold self-center">{technologies.more}</span>
          )}
        </div>
      }
      outerTagsSlot={
        <object className="flex gap-[8px] flex-wrap overflow-visible">
          {tags.map((tag) => {
            return (
              <Link
                key={tag}
                href={{
                  pathname: routeNames.ourWork,
                  query: { category: tag, page: 1 },
                }}
              >
                <span
                  className="
                    transition-colors duration-300 ease-out text-[11px]
                    flex items-center justify-center bg-transparent
                    rounded-full border-[1px] px-[10px] py-[1px]
                    text-center hover:bg-white/10
                    text-white border-white
                    leading-[20px]
                  "
                >
                  {tag}
                </span>
              </Link>
            );
          })}
        </object>
      }
      actionsSlot={null}
      wrapperClassName={cn(``, [
        {
          ['bg-green-9']: themeColor === themeColors.GREEN,
          ['bg-purple-14']: themeColor === themeColors.PURPLE,
          ['bg-purple-15']: themeColor === themeColors.PINK,
          ['bg-purple-9']: themeColor === themeColors.DARK_PINK,
        },
      ])}
      decorationFillColor={cn(``, {
        ['#239745']: themeColor === themeColors.GREEN,
        ['#3A275A']: themeColor === themeColors.PURPLE,
        ['#943175']: themeColor === themeColors.PINK,
        ['#862963']: themeColor === themeColors.DARK_PINK,
      })}
    />
  );
};
