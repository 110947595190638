export const FLIP_DELAY = 200;

export const viewAllCasesCardData = {
  href: '/our-work/',
  title: "View all Syberry's projects",
  preTitle: 'All projects',
  tags: ['Web', 'Desktop', 'Mobile'],
  image: {
    src: '/case-studies/logo.webp',
    height: 249,
    width: 443,
    alt: 'Logo',
  },
};
