import { IAppearFlipAnimationContainer } from '@/organisms/00-ui/AppearFlipAnimationContainer/index.types';
import { FadeAnimationContainer } from '@/molecules/03-decorations/FadeAnimationContainer';
import { FlipAnimationBlock } from '@/components/FlipAnimationBlock';

export const AppearFlipAnimationContainer = ({
  id,
  index,
  isInitialAnimation,
  backfaceElement,
  children,
  callback,
}: IAppearFlipAnimationContainer) => {
  return isInitialAnimation ? (
    <FadeAnimationContainer
      id={id}
      callback={callback}
    >
      {children}
    </FadeAnimationContainer>
  ) : (
    <FlipAnimationBlock
      id={id}
      index={index}
      backfaceSlot={backfaceElement}
      callback={callback}
    >
      {children}
    </FlipAnimationBlock>
  );
};
