'use client';

import { themeColors } from '@/constants/case-studies';
import { CaseStudyCard } from '@/organisms/00-ui/CaseStudyCard';
import { AppearFlipAnimationContainer } from '@/organisms/00-ui/AppearFlipAnimationContainer';
import { IViewAllCasesCardProps } from '@/organisms/00-ui/ViewAllCasesCard/index.types';
import Image from 'next/image';
import { useState } from 'react';
import dynamic from 'next/dynamic';
import { FLIP_DELAY } from '@/organisms/00-ui/ViewAllCasesCard/index.constants';
const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

export const ViewAllCasesCard = ({
  index,
  isInitialAnimation,
  themeColor,
  preTitle,
  backfaceElement,
  title,
  tags,
}: IViewAllCasesCardProps) => {
  const [isPlaying, setPlaying] = useState(false);

  const playVideo = (delay: number) => {
    if (!isPlaying) {
      const timeout = setTimeout(() => {
        setPlaying(true);
      }, delay);

      return () => clearTimeout(timeout);
    }
  };

  return (
    <AppearFlipAnimationContainer
      id="view-all-card-anim"
      index={index || 0}
      isInitialAnimation={isInitialAnimation}
      backfaceElement={backfaceElement}
      callback={() => {
        playVideo(((index || 0) + 1) * FLIP_DELAY);
      }}
    >
      <CaseStudyCard
        themeColor={themeColor || themeColors.DARK_PINK}
        preTitle={preTitle || 'All projects'}
        title={title || `View all Syberry's projects`}
        description=""
        tags={tags || ['Web', 'Desktop', 'Mobile']}
        video={
          <ReactPlayer
            url="/videos/logo-opener.mp4"
            autoPlay={false}
            controls={false}
            playsinline
            playing={isPlaying}
            muted
            width="100%"
            height="100%"
            className="!w-full !h-full bg-purple-14 !pointer-events-none [&>video]:object-cover"
            fallback={
              <Image
                src="/case-studies/logo.webp"
                alt="Logo"
                loading="lazy"
                width={443}
                height={249}
              />
            }
          />
        }
      />
    </AppearFlipAnimationContainer>
  );
};
