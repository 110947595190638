import { ReactNode } from 'react';
import { cn } from '@/helpers/tailwind';

export interface IProps {
  imageSlot: ReactNode;
  preTitleSlot: ReactNode;
  titleSlot: ReactNode;
  descriptionSlot: ReactNode;
  innerTagsSlot: ReactNode;
  outerTagsSlot: ReactNode;
  actionsSlot: ReactNode;
  wrapperClassName?: string;
  decorationFillColor?: string;
}

export const CaseStudyCardTemplate = ({
  imageSlot,
  preTitleSlot,
  titleSlot,
  descriptionSlot,
  innerTagsSlot,
  outerTagsSlot,
  actionsSlot,
  wrapperClassName,
  decorationFillColor,
}: IProps) => {
  return (
    <div
      className={cn(
        `
          w-full h-[480px] max-w-[323px] relative
          flex flex-col shadow-lg
          cursor-pointer overflow-hidden
        `,
        {
          [`${wrapperClassName}`]: wrapperClassName,
        },
      )}
    >
      <div className="flex-1 flex flex-col overflow-hidden">
        <div
          className="
            relative
            overflow-hidden
            basis-[240px] group-hover:basis-[0px]
            shrink-[0] group-hover:shrink-[1]
            group-hover:grow-[0]
            transition-[flex-grow,flex-shrink,flex-basis] duration-500 will-change-transform
          "
        >
          <div className="w-full h-[240px]">{imageSlot}</div>
          <svg
            width="140"
            height="87"
            viewBox="0 0 140 87"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden={true}
            className="
              absolute bottom-[-5px] right-[-1px]
              group-hover:translate-y-[87px]
              duration-500 will-change-transform
            "
          >
            <path
              d="M140 0V87H0L140 0Z"
              fill={decorationFillColor}
            />
          </svg>
        </div>

        <div
          className="
            relative overflow-hidden
            flex flex-col grow-[1]
            transition-[flex-grow,flex-shrink] duration-500
          "
        >
          <div className="flex flex-col px-[24px] pt-[24px] h-full ">
            <div className="w-full h-[24px] shrink-0">{preTitleSlot}</div>
            <div className="w-full mt-[12px] shrink-0 line-clamp-2">{titleSlot}</div>

            <div className="w-full shrink-[1]">{descriptionSlot}</div>

            <div className="w-full shrink-[1] mt-auto">{innerTagsSlot}</div>
          </div>
        </div>
      </div>
      <div className="w-full px-[24px] py-[32px] flex justify-between gap-[10px]">
        <div className="h-[24px] w-full grow-[1]">{outerTagsSlot}</div>
        <div className="h-[24px] flex justify-end grow-0">{actionsSlot}</div>
      </div>
    </div>
  );
};
