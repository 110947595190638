'use client';

import { PropsWithChildren, ReactNode, useEffect } from 'react';
import { motion } from 'framer-motion';

interface IProps extends PropsWithChildren {
  id: string;
  index: number;
  backfaceSlot?: ReactNode;
  callback?: VoidFunction;
}

export const FlipAnimationBlock = ({ children, id, index, backfaceSlot, callback }: IProps) => {
  useEffect(() => {
    if (callback) {
      callback();
    }
  }, [callback]);

  return (
    <div
      style={{
        perspective: 5500,
      }}
    >
      <motion.div
        id={id}
        initial={{ rotateY: 180 }}
        animate={{ rotateY: -0.1 }}
        transition={{
          rotateY: { duration: 0.8, ease: 'easeInOut', delay: (index + 1) * 0.15 },
        }}
        style={{
          transformStyle: 'preserve-3d',
        }}
      >
        <div
          style={{
            backfaceVisibility: 'hidden',
            transform: 'rotateY(0deg)',
          }}
        >
          {children}
        </div>
        {backfaceSlot}
      </motion.div>
    </div>
  );
};
