import caseData from '../../app/our-work/data.json';
import { ICaseStudyCardData } from '@/interfaces/caseStudies';

type TObjectWithIdField = { id: string };
export const caseList: Array<ICaseStudyCardData> = caseData.cases.list;

export const filterById = <T extends TObjectWithIdField>(ids: string[], items: T[] = caseList as unknown as T[]): T[] =>
  items.filter((item) => ids.includes(item.id));

export const filterAndSortByIds = <T extends TObjectWithIdField>(items: T[], ids: string[]): T[] =>
  items
    .filter((item) => ids.includes(String(item.id)))
    .sort((a, b) => ids.indexOf(String(a.id)) - ids.indexOf(String(b.id)));
